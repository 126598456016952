import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cbb4b9ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chat" }
const _hoisted_2 = { class: "chat--header flex items-center" }
const _hoisted_3 = {
  key: 0,
  class: "image"
}
const _hoisted_4 = {
  key: 0,
  alt: "Avatar user"
}
const _hoisted_5 = {
  key: 1,
  class: "image"
}
const _hoisted_6 = { class: "name als fz16" }
const _hoisted_7 = { class: "chat--container" }
const _hoisted_8 = {
  key: 0,
  class: "messages flex-column"
}
const _hoisted_9 = { class: "chat--footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_empty_image_icon = _resolveComponent("empty-image-icon")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.chat.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.chat.user.avatar)
              ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_4, null, 512)), [
                  [_directive_lazy, _ctx.chat.user.avatar.src]
                ])
              : (_openBlock(), _createBlock(_component_empty_image_icon, { key: 1 }))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_empty_image_icon)
          ])),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", null, _toDisplayString(_ctx.currentName), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.chat)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chat.messages, (message) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["message fz14", { my: _ctx.isMyMessage(message) }]),
                key: message._id
              }, _toDisplayString(message.content), 3))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", {
        ref: "message",
        class: "als",
        contenteditable: "true",
        placeholder: "Напишите сообщение...",
        onKeydown: _cache[0] || (_cache[0] = _withKeys(_withModifiers(() => {}, ["exact","prevent"]), ["enter"])),
        onKeyup: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.sendMessage && _ctx.sendMessage(...args)), ["exact"]), ["enter"])),
        onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.vModel && _ctx.vModel(...args)))
      }, null, 544)
    ])
  ]))
}