
import { defineComponent } from "vue-demi";
import { mapGetters } from "vuex";
import { Chat } from "@/interfaces/chat/chat.dto";
import { useAPI } from "@/use";
import CommonChat from "./Chat.vue";
import ChatMain from "./ChatMain.vue";
import { socketJoinRoom, socketLeaveRoom } from "@/socket";

export default defineComponent({
  name: "AdminChatMain",
  data() {
    return {
      chats: [] as Array<Chat.Dto>,
      selectedChat: null as Chat.Dto | null,
    };
  },
  created() {
    this.getChatListMethod();
  },
  mounted() {
    this.onEmits();
  },
  unmounted() {
    this.offEmits();
    socketLeaveRoom(this.socket, this.socket.id, this.chats.map(c => c._id));
  },
  computed: mapGetters(['user']),
  methods: {
    async getChatListMethod() {
      const result = await useAPI().chat.getListMethod();
      this.chats = result.data.reverse();
      socketJoinRoom(this.socket, this.socket.id, this.chats.map(c => c._id));
    },
    async deleteChat(chat_id: string) {
      console.log(chat_id);
    },
    openChat(chat: Chat.Dto) {
      this.$router.push('/admin/chat?chat=' + chat._id);
      this.selectedChat = chat;
    },
    // Emitter
    onEmits() {
      this.emitter.on('message-action', this.messageActionEmitter);
    },
    offEmits() {
      this.emitter.off('message-action', this.messageActionEmitter);
    },
    messageActionEmitter({ message }: { action: string, message: Chat.Message }) {
      const chat = this.chats.find(c => c._id === message.chat);
      if (chat) chat.messages = [...chat.messages, message];
    },
  },
  components: {
    CommonChat,
    ChatMain,
  },
});
