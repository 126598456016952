
import { defineComponent, PropType } from "vue-demi";
import { useAPI } from "@/use";
import { Chat } from "@/interfaces/chat/chat.dto";
import { EmptyImageIcon } from "@/plugins/icons";

export default defineComponent({
  name: 'ChatMain',
  props: {
    chat: {
      type: Object as PropType<Chat.Dto>,
      required: true
    },
    user: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      message: ''
    }
  },
  mounted() {
    this.scrollChat(false);
  },
  computed: {
    currentName() {
      return this.chat.user?.name || "Неавторизованный пользователь";
    },
  },
  methods: {
    async sendMessage() {
      if (!this.chat || !this.message) return;
      (this.$refs.message as HTMLElement).innerText = '';
      
      await useAPI().chat.createMessageMethod({ content: this.message, chat: this.chat?._id });
      this.scrollChat(false);
    },
    isMyMessage(message: Chat.Message) {
      return (message.uuid || message.user) === this.user;
    },
    vModel(event: Event) {
      this.message = (event.target as HTMLInputElement).innerText;
    },
    focusMessageInput() {
      (this.$refs.message as HTMLElement)?.focus();
    },
    scrollChat(animate = true) {
      if (animate) return this.jq('.chat--container').animate({ scrollTop: 99999 }, 500, this.readingMessages);
      return this.jq('.chat--container').animate({ scrollTop: 99999 }, 10, this.readingMessages);
    },
    readingMessages() {
      console.log('reading');
    },
  },
  components: {
    EmptyImageIcon
  }
})
