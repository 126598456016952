import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f3e10c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "image"
}
const _hoisted_2 = {
  key: 0,
  alt: "Avatar user"
}
const _hoisted_3 = {
  key: 1,
  class: "image"
}
const _hoisted_4 = { class: "message flex-column fz14" }
const _hoisted_5 = { class: "name alsb" }
const _hoisted_6 = { class: "content text als" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_empty_image_icon = _resolveComponent("empty-image-icon")!
  const _component_trash_icon = _resolveComponent("trash-icon")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", {
    class: "chat flex items-center cursor",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('openChat')))
  }, [
    (_ctx.chat.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.chat.user.avatar)
            ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_2, null, 512)), [
                [_directive_lazy, _ctx.chat.user.avatar.src]
              ])
            : (_openBlock(), _createBlock(_component_empty_image_icon, { key: 1 }))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_empty_image_icon)
        ])),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.currentName), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.isOwnerLastMessage) + _toDisplayString(_ctx.lastMessage.content), 1)
    ]),
    _createElementVNode("div", {
      class: "action cursor",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('deleteChat')), ["stop"]))
    }, [
      _createVNode(_component_trash_icon)
    ])
  ]))
}