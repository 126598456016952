
import { defineComponent } from "vue-demi";
import ChatMain from "@/components/admin/chat/index.vue";

export default defineComponent({
  name: "ChatView",
  components: {
    ChatMain,
  },
});
