
import { Chat } from "@/interfaces/chat/chat.dto";
import { defineComponent, PropType } from "vue-demi";
import { EmptyImageIcon, TrashIcon } from "@/plugins/icons";

export default defineComponent({
  name: "ChatChat",
  props: {
    chat: {
      type: Object as PropType<Chat.Dto>,
      required: true,
    },
    user: {
      type: String,
      required: true
    }
  },
  computed: {
    isOwnerLastMessage() {
      return (this.lastMessage.user as string) === this.user ? 'Вы: ' : '';
    },
    lastMessage() {
      return this.chat.messages[this.chat.messages.length - 1];
    },
    currentName() {
      return this.chat.user?.name || "Неавторизованный пользователь";
    },
  },
  components: {
    EmptyImageIcon,
    TrashIcon,
  },
});
